import { computed } from "vue"
import { useStore } from "vuex"

const useMeet = () => {

    const store = useStore()

    const loadCompetitions = async () => {
        await store.dispatch('home/loadCompetitions')
    }

    const loadSessions = async (meet_id) => {
        await store.dispatch('home/loadSessions', meet_id)
    }

    const loadEntries = async (meet_id, event_id, isrelay) => {
        const data = {
            meet_id,
            event_id,
            isrelay
        }
        const resp = await store.dispatch('home/loadEntries', data)
        return resp
    }

    const loadEvents = async (meet_id) => {
        const resp = await store.dispatch('home/loadEvents', meet_id)
        return resp
    }

    const loadEventsClub = async (meet_id) => {
        const resp = await store.dispatch('home/loadEventsClub', meet_id)
        return resp
    }

    const loadEventsClub2 = async (meet_id) => {
        const resp = await store.dispatch('home/loadEventsClub2', meet_id)
        return resp
    }

    const loadMain = async (meet_id) => {
        const resp = await store.dispatch('home/loadMain', meet_id)
        return resp
    }

    const loadAthletes = async (meet_id) => {
        const resp = await store.dispatch('home/loadAthletes', meet_id)
        return resp
    }

    const loadClubs = async (meet_id) => {
        const resp = await store.dispatch('home/loadClubs', meet_id)
        return resp
    }

    const loadPoints = async (meet_id) => {
        const resp = await store.dispatch('home/loadPointScore', meet_id)
        return resp
    }

    const loadAgeGroups = async (meet_id) => {
        const resp = await store.dispatch('home/loadAgeGroups', meet_id)
        return resp
    }

    const loadHeats = async (meet_id, event_id, isrelay) => {
        const data = {
            meet_id,
            event_id,
            isrelay
        }
        const resp = await store.dispatch('home/loadHeats', data)
        return resp
    }

    const loadResults = async (meet_id, event_id, isrelay) => {
        const data = {
            meet_id,
            event_id,
            isrelay
        }
        const resp = await store.dispatch('home/loadResults', data)
        return resp
    }

    const reloadEntriesHeatsResults = async (meet_id) => {
        const event_id = store.getters['home/getEventId']
        const is_relay = store.getters['home/getIsRelay']
        if(event_id) {
            const entries = Promise.resolve(loadEntries(meet_id, event_id, is_relay))
            const heats = Promise.resolve(loadHeats(meet_id, event_id, is_relay))
            const results = Promise.resolve(loadResults(meet_id, event_id, is_relay))
            Promise.all([entries, heats, results])
        }
    }

    const numNadadores = (club_code) => {
        return store.getters['home/getClubes'](club_code)
    }
    
    const pruebasClub = (club_code, term) => {
        return store.getters['home/getPruebas'](club_code, term)
    }
    
    const pruebasClub2 = (club_code, term) => {
        return store.getters['home/getPruebas2'](club_code, term)
    }
    
    const loadPruebasNadador = async (meet_id, athleteid) => {
        const data = {
            meet_id,
            athleteid
        }

        const resp = await store.dispatch('home/loadEntriesAthlete', data)
        return resp
    }
    
    const loadPruebasNadador2 = async (meet_id, athleteid) => {
        const data = {
            meet_id,
            athleteid
        }
        const resp = await store.dispatch('home/loadEntriesAthlete2', data)
        return resp
    }
    
    const getNadadoresClub = (club_code) => {
        return store.getters['home/getNadadoresPrueba'](club_code)
    }
    
    const getNadadoresInscritosClub = (club_code, gender) => {
        const nadadores = store.getters['home/getNadadoresInscritos'](club_code, gender)
        return nadadores
    }
    
    const getDeportistasClub = (club_code, term) => {
        const nadadores = store.getters['home/getNadadoresClub'](club_code, term)
        return nadadores

    }

    const getClubesEvento = (term) => {
        const clubes = store.getters['home/getClubesEvento'](term)
        return clubes
    }

    const getNadadoresTotales = (term) => {
        return store.getters['home/getNadadoresTotales'](term)
    }
    

    const getEventosSesion = (numero) => {
        return store.getters['home/getEventosSesion'](numero)
    }
    

    const events = (term) => {
        return store.getters['home/getEvents'](term)
    }

    const competitions = (finalizados) => {
        return store.getters['home/getCompetitions'](finalizados)
    }

    return {
        loadCompetitions,
        loadEntries,
        loadEvents,
        loadEventsClub,
        loadHeats,
        loadResults,
        loadMain,
        loadClubs,
        loadSessions,
        loadAgeGroups,
        loadAthletes,
        reloadEntriesHeatsResults,
        numNadadores,
        pruebasClub,
        getNadadoresClub,
        getNadadoresInscritosClub,
        getDeportistasClub,
        loadPruebasNadador,
        getClubesEvento,
        loadEventsClub2,
        loadPruebasNadador2,
        pruebasClub2,
        getNadadoresTotales,
        events,
        competitions,
        getEventosSesion,
        loadPoints,
        entries: computed(() => store.getters['home/getEntries']),
        heats: computed(() => store.getters['home/getHeats']),
        agegroups: computed(() => store.getters['home/getAgeGroups']),
        results: computed(() => store.getters['home/getResults']),
        isLoading: computed(() => store.getters['home/getLoading']),
        main: computed(() => store.getters['home/getMain']),
        eventsAthlete: computed(() => store.getters['home/getPruebasNadador']),
        isLoadingEntries: computed(() => store.getters['home/isLoadingEntries']),
        sesiones: computed(() => store.getters['home/getSessions']),
        puntuaciones: computed(() => store.getters['home/getPoints']),
    }

}

export default useMeet