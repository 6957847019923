import isAdmClub from './auth-adm-club';

export default {
    name: 'clubes',
    component: () => import(/* webpackChunkName: "clubes" */ '@/modules/club/layouts/ClubLayout.vue'),
    children: [
        {
            path: '',
            name: 'club-home',
            component: () => import(/* webpackChunkName: "club-hom" */ '@/modules/club/views/EventosView.vue'),
        },
        {
            path: 'eventos',
            name: 'club-eventos',
            component: () => import(/* webpackChunkName: "club-eventos" */ '@/modules/club/views/EventosView.vue'),
        },
        {
            path: 'usuarios',
            name: 'club-usuarios',
            beforeEnter: [isAdmClub],
            component: () => import(/* webpackChunkName: "club-usuarios" */ '@/modules/club/views/UsuariosView.vue'),
        },
        {
            path: 'evento/:id',
            name: 'club-evento',
            component: () => import(/* webpackChunkName: "club-evento" */ '@/modules/club/views/EventoView.vue'),
            props: route => ({id: route.query.id}),
        },
        {
            path: 'solicitudes/:id',
            name: 'club-solicitudes',
            component: () => import(/* webpackChunkName: "club-solicitudes" */ '@/modules/club/views/SolicitudesView.vue'),
            props: route => ({id: route.query.id}),
        },
        {
            path: 'password',
            name: 'club-password',
            component: () => import(/* webpackChunkName: "club-password" */ '@/modules/club/views/PasswordView.vue'),
        },
    ]
}