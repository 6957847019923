export const currentState = (state) => {
    return state.status
}

export const getMeets = (state) => {
    return state.lstMeets
}

export const getSelectClubes = (state) => {
    return state.selectClubes
}

export const getClubes = (state) => (term) => {
    if(term.length === 0) return state.lstClubes

    return state.lstClubes.filter(club => club.club_nombre.toLowerCase().includes(term.toLocaleLowerCase()))
}

export const getUsuarios = (state) => (filtro) => {
    if(filtro.term.length === 0) {
        return state.lstUsuarios
    } else {
        if(filtro.field == "CLUB") {
            return state.lstUsuarios.filter(usuario => usuario.club_nombre.toLowerCase().includes(filtro.term.toLocaleLowerCase()))
        } else {
            return state.lstUsuarios.filter(usuario => usuario.usu_email.toLowerCase().includes(filtro.term.toLocaleLowerCase()))
        }
    }
}
