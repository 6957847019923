import appApi from "@/api/appApi"
import authApi from "@/api/authApi"

export const checkAuthentication = async ({commit}) => {
    const idToken = localStorage.getItem('admToken')
    if(!idToken) {
        commit('logout')
        return {ok: false, message: 'No hay token'}
    } 

    try {
        const {data} = await appApi.post('/check-secretaria', {})
        commit('loginSecretaria', data)
        return {ok: true}
        
    } catch (error) {
        commit('logout')
        return {ok: false, message: error}
    }
}

export const loginSecretaria = async ({commit}, datos) => {

    const {email, password} = datos
    const payload = {
        email,
        password,
        fed_id: process.env.VUE_APP_FED_ID
    }

    try {
        const {data} = await authApi.post('/login-secretaria', payload)
        if(data.error) 
            return {ok: false, message: data.msg}
        console.log(data.sgf);
        commit('loginSecretaria', data, data.sgf)
        return {ok: true}
        
    } catch (error) {
        console.log(error)
        return {ok: false, message: error}
    }


}

export const loadCompeticiones = async({commit}) => {
    const datos = {
        type: 'secretaria',
    }
    const {data} = await appApi.post('/meets', datos)
    commit('setCompeticiones', data.data)
}

export const loadSolicitudes = async({commit}, datos) => {
    try {
        const {data} = await appApi.post('/solicitudes', datos)
        commit('setSolicitudes', data.solicitudes)
        return {ok: true}
        
    } catch (error) {
        console.log(error)
        return {ok: false, message: error}
    }
}

export const loadSeries = async ({ commit }, datos) => {
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${datos.meet_id}/heats/${datos.event_id}.json?t=${date}`)
    const data = await response.json()
    //const { data } = await swimRankingsApi.get(`/${datos.meet_id}/heats/${datos.event_id}.json`, { cache: false })
    if (!data) {
        commit('setSeries', [])
        return
    }

    const { heats } = data;
    let newHeats = []
    for (const heat of heats) {
        let entries = []
        for(const entry of heat.entries) {
            const relevo = {
                meet_id: datos.meet_id,
                clubcode: entry.clubcode,
                eventnumber: datos.event_number,
                teamnumber: entry.teamnumber,
                round: datos.round_raw,
                heat: heat.heatinfo.code,
                lane: entry.lane
            }
            const {data} = await appApi.post('/composicion-relevo', relevo)
            entries.push({
                ...entry,
                composicion: data.composicion
            })
        }
        newHeats.push({
            ...heat,
            isrelay: true,
            entries
        })
    }
    commit('setSeries', newHeats)
}

const getStroke = (stroke) => {
    switch (stroke) {
        case "1": return 'Libre'
        case "2": return 'Espalda'
        case "3": return 'Braza'
        case "4": return 'Mariposa'
        case "5": return 'Estilos'
        default: return ''
    }
}

const getGender = (gender) => {
    switch (gender) {
        case "1": return 'Masculino'
        case "11": return 'Masculino'
        case "2": return 'Femenino'
        case "12": return 'Femenino'
        case "3": return 'Mixto'
        default: 'N/D'
    }
}

export const loadRelays = async({commit}, meet_id) => {
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/events.json?t=${date}`)
    const data = await response.json()
    let events = []
    for (let clave in data) {
        if ((data[clave].number > 0) && (data[clave].isrelay)) {
            let round_name = ''
            switch(data[clave].round) {
                case '1': 
                    round_name = 'Final Contra Reloj'
                    break
                case '2': 
                    round_name = 'Preliminar'
                    break
                case '9': 
                    round_name = 'Final'
                    break
                case '8': 
                    round_name = 'Serie rápida'
                    break
            }
            const evento = {
                id: data[clave].id,
                number: data[clave].number,
                distance: data[clave].distance,
                stroke: getStroke(data[clave].stroke),
                gender: getGender(data[clave].gender),
                gender_raw: data[clave].gender,
                date: data[clave].date,
                time: data[clave].time,
                agegroup: (data[clave].agegroup.name) ? data[clave].agegroup.name : ``,
                isrelay: data[clave].isrelay,
                round: round_name,
                round_raw: data[clave].round,
                selected: false,
            }
            //console.log(evento);
            events.push(evento)
        }
    }
    commit('setRelays', events)
}

export const loadUsuariosClubes = async({commit}) => {
    const {data} = await appApi.get(`/secretaria-usuarios-clubes/${process.env.VUE_APP_FED_ID}`)
    commit('setUsuarios', data.data)
}
