<template>
      <div class="card" ref="lblTop">
        <div class="card-header border-0 btn-toolbar justify-content-between" role="toolbar">
        <button class="btn light btn-primary btn-xs" @click="recargar">
            <i class="fa-solid fa-refresh fa-lg"></i>
        </button>

        <div v-if="isLoading" class="btn-group" style="font-size:13px">
            <i class="fa-solid fa-spinner fa-spin-pulse fa-2x p-5"></i>
        </div>

        <div class="dropdown custom-dropdown mb-0">
            <div class="btn light btn-primary btn-xs" data-bs-toggle="dropdown">
                <i class="fa-solid fa-bars fa-lg"></i>
            </div>
            <div class="dropdown-menu dropdown-menu-end">
                <router-link class="dropdown-item" :to="{name: 'clubes'}">Acceso Clubes</router-link>
                <router-link class="dropdown-item" :to="{name: 'secretaria-home'}">Acceso Árbitros</router-link>
            </div>
	    </div>
  

        </div>
    </div>
      <div class="card">
        <div class="card-header border-0 btn-toolbar justify-content-between" role="toolbar">
            <p class="mb-0" style="font-size: 13px; ">Mostrar eventos finalizados</p>
            <label class="toggle mb-0" for="myToggle">
                <input class="toggle__input" name="" type="checkbox" id="myToggle" v-model="checkCompeticiones">
                <div class="toggle__fill"></div>
            </label>
	    </div>
    </div>
  
    <div v-if="!isLoading">
        <div  v-if="competitions.length > 0" class="row">
            <div 
            v-for="competition in competitions(checkCompeticiones)" :key="competition.meet_id"
            class="col-xl-3 col-sm-12">
               <Evento :competicion="competition"/>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-md-12" style="font-size: 13px;">
                No existen campeonatos disponibles
            </div>
        </div>
    </div>
</template>

<script>
import { onActivated, ref } from 'vue'
import useMeet from '../composables/useMeet'
import Evento from '../components/Event.vue'

export default {
    components: {
        Evento
    },
    setup() {

        const isLoading = ref(true)
        const checkCompeticiones = ref(false)
        const { loadCompetitions, competitions } = useMeet()

        onActivated(async () => {
            console.log('cargamos');
            await loadCompetitions()
            isLoading.value = false
        })

        return {
            competitions,
            isLoading,
            checkCompeticiones,
            recargar: async () => {
                isLoading.value = true
                await loadCompetitions()
                isLoading.value = false
            }
        }

    }
}

</script>
<style scoped>
.toggle {
    --width: 40px;
    --height: calc(var(--width) / 2);
    --border-radius: calc(var(--height) / 2);

    display: inline-block;
    cursor: pointer;
}

.toggle__input {
    display: none;
}

.toggle__fill {
    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background: #dddddd;
    transition: background 0.2s;
}

.toggle__fill::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: var(--height);
    width: var(--height);
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: var(--border-radius);
    transition: transform 0.2s;
}

.toggle__input:checked ~ .toggle__fill {
    background: #0d99ff;
}

.toggle__input:checked ~ .toggle__fill::after {
    transform: translateX(var(--height));
}

</style>