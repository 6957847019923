import appApi from "@/api/appApi"
import authApi from "@/api/authApi"

export const checkAuthentication = async ({commit}) => {
    const idToken = localStorage.getItem('admToken')
    if(!idToken) {
        commit('logout')
        return {ok: false, message: 'No hay token'}
    } 

    try {
        const {data} = await appApi.post('/check-club', {})

        commit('loginClub', data)
        return {ok: true}
        
    } catch (error) {
        commit('logout')
        return {ok: false, message: error}
    }
}

export const loginClub = async ({commit}, datos) => {

    const {email, password} = datos
    const payload = {
        email,
        password,
        fed_id: process.env.VUE_APP_FED_ID
    }

    try {
        const {data} = await authApi.post('/login-club', payload)
        if(data.error) 
            return {ok: false, message: data.msg}
        commit('loginClub', data)
        return {ok: true}
        
    } catch (error) {
        console.log(error)
        return {ok: false, message: error}
    }


}

export const loadSolicitudes = async({commit}, datos) => {
    try {
        const {data} = await appApi.post('/solicitudes-club', datos)
        commit('setSolicitudes', data.solicitudes)
        return {ok: true}
        
    } catch (error) {
        console.log(error)
        return {ok: false, message: error}
    }
}

export const loadCompeticiones = async({commit}, filter) => {
    const datos = {
        type: 'club',
        filter
    }
    const {data} = await appApi.post('/meets', datos)
    commit('setCompeticiones', data.data)
}

export const loadUsuarios = async({commit}, clubId) => {

    const {data} = await appApi.get(`/club-usuarios/${clubId}`)
    commit('setUsuarios', data.data)
}

