/*
export default () => ({

})
*/
export default () => ({
    fed_id: null,
    competitions: [],
    sessions: [],
    entries: [],
    events: [],
    eventsclub: [],
    heats: [],
    results: [],
    main: [],
    agegroups: [],
    athletes: [],
    clubs: [],
    eventsAthlete: [],
    event_id: null,
    isLoading: true,
    isrelay: false,
    isLoadingEntries: false,
    pointscore: []
})