export default {
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/modules/admin/layouts/AdminLayout.vue'),
    children: [
        {
            path: '',
            name: 'admin-home',
            component: () => import(/* webpackChunkName: "admin-meets" */ '@/modules/admin/views/MeetsView.vue'),
        },
        {
            path: 'meets',
            name: 'meets',
            component: () => import(/* webpackChunkName: "admin-meets" */ '@/modules/admin/views/MeetsView.vue'),
        },
        {
            path: 'clubes',
            name: 'admin-clubes',
            component: () => import(/* webpackChunkName: "admin-clubes" */ '@/modules/admin/views/ClubesView.vue'),
        },
        {
            path: 'usuarios',
            name: 'admin-usuarios',
            component: () => import(/* webpackChunkName: "admin-usuarios" */ '@/modules/admin/views/UsuariosView.vue'),
        }
    ]
}