import { createRouter, createWebHashHistory } from 'vue-router'
import clubRouter from '@/modules/club/router'
import adminRouter from '@/modules/admin/router'
import secretariaRouter from '@/modules/secretaria/router'
import HomeLayout from '@/modules/home/layouts/HomeLayout.vue'
import EventsView from '@/modules/home/views/EventsView.vue'
import CompetitionView from '@/modules/home/views/CompetitionView.vue'
import isAuthenticatedGuardAdmin from '@/modules/admin/router/auth-guard'
import isAuthenticatedGuardClub from '@/modules/club/router/auth-guard'
import isAuthenticatedGuardSecretaria from '@/modules/secretaria/router/auth-guard'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeLayout,
    children: [{
      path: '',
      name: 'eventos',
      component: EventsView,
    },
    {
      path: 'competition/:id',
      name: 'competicion',
      component: CompetitionView,
      props: route => ({id: route.query.id}),
      children: [
        {
        path: '',
        name: 'home-pruebas',
        component: () => import(/* webpackChunkName: "home-pruebas" */ '@/modules/home/views/HomeView.vue'),
      },
        {
        path: 'clubes',
        name: 'home-clubes',
        component: () => import(/* webpackChunkName: "home-pruebas" */ '@/modules/home/views/ClubesView.vue'),
      },
        {
        path: 'athletes',
        name: 'home-athletes',
        component: () => import(/* webpackChunkName: "home-pruebas" */ '@/modules/home/views/AthletesView.vue'),
      },
        {
        path: 'points',
        name: 'home-points',
        component: () => import(/* webpackChunkName: "home-pruebas" */ '@/modules/home/views/PointsView.vue'),
      },
    ]
    }
    ]
  },
  {
    path: '/club',
    beforeEnter: [isAuthenticatedGuardClub],
    ...clubRouter
  },
  {
    path: '/admin',
    beforeEnter: [isAuthenticatedGuardAdmin],
    ...adminRouter
  },
  {
    path: '/secretaria',
    beforeEnter: [isAuthenticatedGuardSecretaria],
    ...secretariaRouter
  },
  {
    path: '/auth-admin',
    name: 'auth-admin',
    component: () => import(/* webpackChunkName: "auth-admin" */ '@/modules/admin/layouts/LoginAdminLayout.vue'),
  },
  {
    path: '/auth-club',
    name: 'auth-club',
    component: () => import(/* webpackChunkName: "atuh-club" */ '@/modules/club/layouts/LoginClubLayout.vue'),
  },
  {
    path: '/auth-secretaria',
    name: 'auth-secretaria',
    component: () => import(/* webpackChunkName: "secretaria" */ '@/modules/secretaria/layouts/LoginSecreLayout.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
