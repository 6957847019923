import store from '@/store'

const isAdmClub = async (to, from, next) => {

    const rol = store.getters['club/getRol']
    if(rol == 'ADM_CLUB')
         next()
    else next({name: 'club-eventos'})
}

export default isAdmClub