export default () => ({
    status: 'authenticating',
    idToken: null,
    club_id: 0,
    club_nombre: '',
    usu_id: 0,
    rol: '',
    club_code: '',
    solicitudes: [],
    competiciones: [],
    usuariosClub: []
})