import { createStore } from 'vuex'
import homeModule from '@/modules/home/store'
import adminModule from '@/modules/admin/store'
import clubModule from '@/modules/club/store'
import secretariaModule from '@/modules/secretaria/store'

const store = createStore({
    modules: {
        home: homeModule,
        admin: adminModule,
        club: clubModule,
        secretaria: secretariaModule,
    }
})

export default store