<template>
  <div class="card box-hover cursor" @click="goToMeet(competicion.meet_id)">
    <div class="card-header">
      <h5 class="mb-0 blog-title">
        #{{ competicion.name.toUpperCase() }}
      </h5>
    </div>
    <div class="card-body">
      <div class="row mt-4">
        <div class="col-4 text-center border-end">
          <i class="fa-solid fa-water-ladder fa-2x azul"></i>
          <p>{{ competicion.course }}</p>
        </div>
        <div class="col-4 text-center border-end">
          <i class="fa-solid fa-map-pin fa-2x azul"></i>
          <p>{{ competicion.city.toUpperCase() }}</p>
        </div>
        <div class="col-4 text-center">
          <i class="fa-solid fa-calendar-days fa-2x azul"></i>
          <p>{{ competicion.days }}</p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4 text-center border-end">
          <i class="fa-solid fa-flag fa-2x azul"></i>
          <p>{{ competicion.statistic_entries }}</p>
        </div>
        <div class="col-4 text-center border-end">
          <i class="fa-solid fa-people-group fa-2x azul"></i>
          <p>{{ competicion.statistic_relays }}</p>
        </div>
        <div class="col-4 text-center">
          <i class="fa-solid fa-person-swimming fa-2x azul"></i>
          <p>{{ competicion.statistic_athletes }}</p>
        </div>
      </div>
    </div>

    <div class="card-footer d-flex align-items-center justify-content-between" style="padding-bottom: 0px">
      <div class="footer-data">
        <span>Inicio</span>
        <p><b>{{ competicion.startdate }}</b></p>
      </div>
      <div class="footer-data">
        <span>Fin</span>
        <p><b>{{ competicion.enddate }}</b></p>
      </div>
      <div class="footer-data">
        <span>Estado</span>
        <p><span class="badge badge-sm" :class="competicion.status_class">{{ competicion.status_name }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import router from '@/router';
export default {
  props: {
    competicion: {
        type: Object,
        required: true
    }
  },
  setup(props) {

    const {competicion} = toRefs(props)
    const goToMeet = (meet_id) => {
      router.push({ name: 'home-pruebas', params: {id: meet_id}})
    }

     return {
      competicion,
      goToMeet
    }

  },
};
</script>