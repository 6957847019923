export default {
    name: 'secretaria',
    component: () => import(/* webpackChunkName: "secretaria" */ '@/modules/secretaria/layouts/SecretariaLayout.vue'),
    children: [
        {
            path: '',
            name: 'secretaria-home',
            component: () => import(/* webpackChunkName: "secretaria-home" */ '@/modules/secretaria/views/EventosView.vue'),
        },
        {
            path: 'eventos',
            name: 'secretaria-eventos',
            component: () => import(/* webpackChunkName: "secretaria-eventos" */ '@/modules/secretaria/views/EventosView.vue'),
        },
        {
            path: 'password',
            name: 'secretaria-password',
            component: () => import(/* webpackChunkName: "secretaria-password" */ '@/modules/secretaria/views/PasswordView.vue'),
        },
        {
            path: 'usuarios-clubes',
            name: 'secretaria-clubes',
            component: () => import(/* webpackChunkName: "secretaria-clubes" */ '@/modules/secretaria/views/UsuariosView.vue'),
        },
        {
            path: 'evento/:id',
            name: 'secretaria-evento',
            component: () => import(/* webpackChunkName: "secretaria-evento" */ '@/modules/secretaria/views/EventoView.vue'),
            props: route => ({id: route.query.id}),
        },
    ]
}