import appApi from "@/api/appApi"
import authApi from "@/api/authApi"

export const checkAuthentication = async ({commit}) => {
    const idToken = localStorage.getItem('admToken')
    if(!idToken) {
        commit('logout')
        return {ok: false, message: 'No hay token'}
    } 

    try {
        const {data} = await appApi.post('/check-admin', {})

        commit('loginAdmin', data.token)
        return {ok: true}
        
    } catch (error) {
        commit('logout')
        return {ok: false, message: error}
    }
}

export const loginAdmin = async ({commit}, datos) => {

    const {email, password} = datos
    const payload = {
        email,
        password,
        fed_id: process.env.VUE_APP_FED_ID
    }

    try {
        const {data} = await authApi.post('/login-admin', payload)
        const {error, token, msg} = data

        if(error) 
            return {ok: false, message: msg}
        
        commit('loginAdmin', token)
        return {ok: true}
        
    } catch (error) {
        console.log(error)
        return {ok: false, message: error}
    }


}

export const cargaListadoMeets = async({commit}) => {
    const {data} = await appApi.post('/meets', {type: 'admin'})
    if(data.status == 200) {
        commit('listadoMeets', data.data)
    } else {
        commit('listadoMeets', [])
    }
    return {ok: true}
}

export const cargaClubes = async({commit}) => {
    const {data} = await appApi.get('/adm-clubes')
    if(data.status == 200) {
        commit('listadoClubes', data.data)
    } else {
        commit('listadoClubes', [])
    }
    return {ok: true}
}

export const cargaUsuarios = async({commit}) => {
    const {data} = await appApi.get('/adm-usuarios')
    if(data.status == 200) {
        commit('listadoUsuarios', data.data)
    } else {
        commit('listadoUsuarios', [])
    }
    return {ok: true}
}

export const cargaClubesSelect = async({commit}) => {
    const {data} = await appApi.get('/adm-clubes')
    if(data.status == 200) {
        let clubes = []
        for(const club of data.data) {
            clubes.push({
                label: club.club_nombre,
                id: club.club_id
            })
        }
        commit('listadoSelectClubes', clubes)
    } else {
        commit('listadoSelectClubes', [])
    }
    return {ok: true}
}