<template>
  <div id="main-wrapper show">
    <nav class="navbar fixed-top navbar-expand navbar-light fondo">
      <div class="container-fluid">
        <a class="navbar-brand">
          <img :src="logo" class="img-fluid" style="max-width: 25px" /> </a>
          <span class="navbar-text" style="color:white;"><b>{{ fed_nombre }}</b> </span>
      </div>
    </nav>
    <div class="content-body" style="margin-top: 50px;">
      <div class="container-fluid">
        <router-view v-slot="{ Component, route }">
          <keep-alive>
            <component :is="Component" :key="route.name"> </component>
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/logo.png";

export default {
  setup() {
    const fed_nombre = process.env.VUE_APP_FED_NOMBRE
    return {
      logo,
      fed_nombre
    };
  },
};
</script>

<style scoped>
.fondo {
/*
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgba(9, 95, 130, 1) 100%
  );
*/
  background: #095f82;
  height: 50px;
}

</style>
