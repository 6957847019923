export default () => ({
    status: 'authenticating',
    idToken: null,
    club_id: 0,
    usu_id: 0,
    club_nombre: '',
    email: '',
    rol: '',
    sgf: false,
    solicitudes: [],
    competiciones: [],
    relays: [],
    series: [],
    usuarios: []
})