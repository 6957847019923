/*
export const myMutation = (state) => {
    
}
*/

export const setFederation = (state, fed_id) => {
    state.fed_id = fed_id
}

export const setCompetitions = (state, competitions) => {
    state.competitions = []
    state.competitions = competitions
}

export const setEntries = (state, entries) => {
    state.entries = entries
}

export const setEvents = (state, events) => {
    state.events = events
}

export const setEvents2 = (state, events) => {
    state.eventsclub = events
}

export const setHeats = (state, heats) => {
    state.heats = heats
}

export const setEventId = (state, eventId) => {
    state.event_id = eventId
}

export const setMain = (state, main) => {
    state.main = main
}

export const setAthletes = (state, athletes) => {
    state.athletes = athletes
}

export const setClubs = (state, clubs) => {
    state.clubs = clubs
}

export const setAgegroups = (state, agegroups) => {
    state.agegroups = agegroups
}

export const setResults = (state, results) => {
    state.results = results
}

export const setLoading = (state, isLoading) => {
    state.isLoading = isLoading
}

export const setRelay = (state, isrelay) => {
    state.isrelay = isrelay
}

export const setEventsAthlete = (state, eventos) => {
    state.eventsAthlete = eventos
}

export const isLoadingEntries = (state, loading) => {
    state.isLoadingEntries = loading
}

export const setSessions = (state, sessions) => {
    state.sessions = sessions
}

export const setPointScore = (state, pointscore) => {
    state.pointscore = pointscore
}