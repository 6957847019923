export const getClubId = (state) => {
    return state.club_id
}

export const getUsuId = (state) => {
    return state.usu_id
}

export const getClubNombre = (state) => {
    return state.club_nombre
}

export const getRol = (state) => {
    return state.rol
}

export const getClubCode = (state) => {
    return state.club_code
}

export const getSolicitudes = (state) => {
    return state.solicitudes
}

export const getCompeticiones = (state) => (finalizados) => {
    if(finalizados) {
        return state.competiciones
    } else {
        const lstCompetitions = []
        const hoy = new Date()
        for(const competition of state.competiciones) {
            const fechaFinEvento = new Date(competition.meet_fecha_fin)
            const diferencia = (hoy.getTime() - fechaFinEvento.getTime()) / 1000 / 60 / 60 / 24
            if(diferencia < 2) {
                lstCompetitions.push(competition)
            }
        }
        return lstCompetitions
    }
}

export const getUsuarios = (state) => {
    return state.usuariosClub
}
