export const loginAdmin = (state, token) => {
    if(token) {
        localStorage.setItem('admToken', token)
        state.idToken = token
    }

    state.status = 'authenticated'
}

export const logout = (state) =>  {
    state.idToken = null
    state.status = 'not-authenticated'

    localStorage.removeItem('admToken')
}

export const listadoMeets = (state, lstMeets) => {
    state.lstMeets = lstMeets
}

export const listadoClubes = (state, lstClubes) => {
    state.lstClubes = lstClubes
}

export const listadoUsuarios = (state, lstUsuarios) => {
    state.lstUsuarios = lstUsuarios
}

export const listadoSelectClubes = (state, clubes) => {
    state.selectClubes = clubes
}