/*
export const myAction = async ({comit}) => {

}
*/

import appApi from "@/api/appApi"

export const loadPointScore = async ({commit}, meet_id) => {
    const lstPoints = []
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/main.json?t=${date}`,)
    const data = await response.json()
    if(data.pointscores) {
        for(const pointscore of data.pointscores) {
            const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/pointscores/${pointscore.id}.json?t=${date}`)
            const dataPoint = await response.json()
            const point = {
                id: pointscore.id,
                name: pointscore.name,
                categories: dataPoint.categories,
                status: data.status
            }
            lstPoints.push(point)
        }
    } 
    commit('setPointScore', lstPoints)

}

export const loadCompetitions = async ({ commit }) => {
    commit('setFederation', process.env.VUE_APP_FED_ID)
    const competitions = []
    const resp = await appApi.post('/eventos', {
        fed_id: process.env.VUE_APP_FED_ID,
        type: 'all'
    })
    /*
    const response = await fetch(`${process.env.VUE_APP_API_URL}/t/meets/${datos.meet_id}/entries/${datos.event_id}.json?t=${date}`)
    const data = await response.json()
    */
   for(const meet of resp.data.data) {
        const date=new Date().getTime()
        const response = await fetch(`https://live.swimrankings.net/meets/${meet.meet_id}/main.json?t=${date}`,)
        const data = await response.json()
        //console.log(data)
        let estado = ''
        let estado_class = ''
        switch(data.status) {
            case 1:
                estado = 'SIN EMPEZAR'
                estado_class = 'badge-success'
            break
            case 2:
                estado = 'SIN EMPEZAR'
                estado_class = 'badge-success'
            break
            case 3:
                estado = 'EN CURSO'
                estado_class = 'badge-danger'
            break
            case 5:
                estado = 'FINALIZADA'
                estado_class = 'badge-primary'
            break
            default:
                estado = 'FINALIZADA'
                estado_class = 'badge-primary'
        }
        competitions.push({
            meet_id: meet.meet_id,
            name: data.name,
            course: (data.course == 1) ? '50m' : '25m',
            city: data.city,
            days: `${data.days} dia(s)`,
            statistic_entries: data.statistic.entries,
            statistic_relays: data.statistic.relays,
            statistic_athletes: data.statistic.athletes,
            startdate: data.startdate,
            enddate: data.enddate,
            status: data.status,
            status_name: estado,
            status_class: estado_class
        })

   }

    commit('setCompetitions', competitions)

}

const getAgregroupName = (agegroups, id) => {
    const agegroup = agegroups.find(age => age.id == id)
    if(!agegroup) return ``
    return {...agegroup}
}

const difTimeEntryResult = (entrytime, swimtime) => {
    let totalSegsEntry = 0
    let totalSegsSwim = 0

    let totalTimeEntry = 0;
    let totalTimeSwim = 0;

    const h1 = entrytime.split(':')
    if(h1.length > 1) {
        const m1 = h1[1].split('.')
        totalSegsEntry = (parseInt(h1[0]) * 60 * 60) + (parseInt(m1[0]))
        totalTimeEntry = `${totalSegsEntry}.${m1[1]}`
    } else {
        const m1 = entrytime.split('.')
        totalSegsEntry = (parseInt(m1[0])) 
        totalTimeEntry = `${totalSegsEntry}.${m1[1]}`
    }

    const h2 = swimtime.split(':')
    if(h2.length > 1) {
        const m2 = h2[1].split('.')
        totalSegsSwim += (parseInt(h2[0]) * 60 * 60) + (parseInt(m2[0]))
        totalTimeSwim = `${totalSegsSwim}.${m2[1]}`
    } else {
        const m2 = swimtime.split('.')
        totalSegsSwim += (parseInt(m2[0])) 
        totalTimeSwim = `${totalSegsSwim}.${m2[1]}`
    }

    const segs = Math.round((parseFloat(totalTimeSwim) - parseFloat(totalTimeEntry))*100) / 100

    return segs
}

const getStroke = (stroke) => {
    switch (stroke) {
        case "1": return 'Libre'
        case "2": return 'Espalda'
        case "3": return 'Braza'
        case "4": return 'Mariposa'
        case "5": return 'Estilos'
        default: return ''
    }
}

const getGender = (gender) => {
    switch (gender) {
        case "1": return 'Masculino'
        case "11": return 'Masculino'
        case "2": return 'Femenino'
        case "12": return 'Femenino'
        case "3": return 'Mixto'
        case "0": return 'Mixto'
        default: 'N/D'
    }
}

export const loadEntries = async ({ commit }, datos) => {
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${datos.meet_id}/entries/${datos.event_id}.json?t=${date}`)
    const data = await response.json()
    //const { data } = await swimRankingsApi.get(`/${datos.meet_id}/entries/${datos.event_id}.json`, { cache: false })
    if (!data) {
        commit('setEntries', [])
        commit('setEventId', null)
        commit('setRelay', false)
        return
    }
    const { entries } = data;
    let newEntries = []
    for (const entry of entries) {
        newEntries.push({
            ...entry,
            isrelay: datos.isrelay
        })
    }

    commit('setEventId', datos.event_id)
    commit('setEntries', newEntries)
    commit('setRelay', datos.isrelay)
}

export const loadEventsClub = async ({ commit }, meet_id) => {
    commit('isLoadingEntries', true)
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/events.json?t=${date}`)
    const data = await response.json()
    let events = []
    for (let clave in data) {
        if (data[clave].number > 0) {
            const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/entries/${data[clave].id}.json?t=${date}`)
            const dataEntrie = await response.json()
            let round_name = ''
            switch(data[clave].round) {
                case '1': 
                    round_name = 'Final Contra Reloj'
                    break
                case '2': 
                    round_name = 'Preliminar'
                    break
                case '9': 
                    round_name = 'Final'
                    break
                case '8': 
                    round_name = 'Serie rápida'
                    break
            }
            const evento = {
                id: data[clave].id,
                number: data[clave].number,
                distance: data[clave].distance,
                stroke: getStroke(data[clave].stroke),
                gender: getGender(data[clave].gender),
                gender_raw: data[clave].gender,
                prueba: `${data[clave].distance} ${getStroke(data[clave].stroke)} ${getGender(data[clave].gender)}`,
                date: data[clave].date,
                time: data[clave].time,
                isrelay: data[clave].isrelay,
                round: round_name,
                round_raw: data[clave].round,
                selected: false,
                entries: dataEntrie.entries
            }

            events.push(evento)
        }
    }
    commit('setEvents', events)
    commit('isLoadingEntries', false)
}

export const loadEventsClub2 = async ({ commit }, meet_id) => {
    commit('isLoadingEntries', true)
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/events.json?t=${date}`)
    const data = await response.json()
    let events = []
    for (let clave in data) {
        if (data[clave].number > 0) {
            const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/entries/${data[clave].id}.json?t=${date}`)
            const dataEntrie = await response.json()
            let round_name = ''
            switch(data[clave].round) {
                case '1': 
                    round_name = 'Final Contra Reloj'
                    break
                case '2': 
                    round_name = 'Preliminar'
                    break
                case '9': 
                    round_name = 'Final'
                    break
                case '8': 
                    round_name = 'Serie rápida'
                    break
            }
            const evento = {
                id: data[clave].id,
                number: data[clave].number,
                distance: data[clave].distance,
                stroke: getStroke(data[clave].stroke),
                gender: getGender(data[clave].gender),
                gender_raw: data[clave].gender,
                prueba: `${data[clave].distance} ${getStroke(data[clave].stroke)} ${getGender(data[clave].gender)}`,
                date: data[clave].date,
                time: data[clave].time,
                isrelay: data[clave].isrelay,
                round: round_name,
                round_raw: data[clave].round,
                selected: false,
                entries: dataEntrie.entries
            }

            events.push(evento)
        }
    }
    commit('setEvents2', events)
    commit('isLoadingEntries', false)
}

export const loadEvents = async ({ commit }, meet_id) => {
    commit('setLoading', true)
    commit('setHeats', [])
    commit('setEntries', [])
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/events.json?t=${date}`)
    const data = await response.json()
    let events = []
    for (let clave in data) {
        if (data[clave].number > 0) {
            const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/results/${data[clave].id}.json?t=${date}`)
            const dataResult = await response.json()
            let estado = ''
            let estado_class = ''
            switch(dataResult.status) {
                case 0:
                    estado = 'SIN SORTEAR'
                    estado_class = 'badge-warning'
                break
                case 1:
                    estado = 'SIN EMPEZAR'
                    estado_class = 'badge-warning'
                break
                case 2:
                    estado = 'SIN EMPEZAR'
                    estado_class = 'badge-success'
                break
                case 3:
                    estado = 'EN CURSO'
                    estado_class = 'badge-danger'
                break
                case 5:
                    estado = 'FINALIZADA'
                    estado_class = 'badge-primary'
                break
            }
            let round_name = ''
            switch(data[clave].round) {
                case '1': 
                    round_name = 'Final Contra Reloj'
                    break
                case '2': 
                    round_name = 'Preliminar'
                    break
                case '9': 
                    round_name = 'Final'
                    break
                case '8': 
                    round_name = 'Serie rápida'
                    break
            }

            const evento = {
                id: data[clave].id,
                number: data[clave].number,
                distance: data[clave].distance,
                stroke: getStroke(data[clave].stroke),
                gender: getGender(data[clave].gender),
                date: data[clave].date,
                time: data[clave].time,
                isrelay: data[clave].isrelay,
                round: round_name,
                round_raw: data[clave].round,
                selected: false,
                search: `${data[clave].distance} ${getStroke(data[clave].stroke)} ${getGender(data[clave].gender)}`,
                estado,
                estado_class
            }

            //console.log(evento);
            events.push(evento)
        }
    }
    commit('setEvents', events)
    commit('setLoading', false)
}

export const loadHeats = async ({ commit }, datos) => {
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${datos.meet_id}/heats/${datos.event_id}.json?t=${date}`)
    const data = await response.json()
    //const { data } = await swimRankingsApi.get(`/${datos.meet_id}/heats/${datos.event_id}.json`, { cache: false })
    if (!data) {
        commit('setHeats', [])
        commit('setEventId', null)
        return
    }

    const { heats } = data;
    let newHeats = []
    for (const heat of heats) {
        newHeats.push({
            ...heat,
            isrelay: datos.isrelay
        })
    }
    commit('setEventId', datos.event_id)
    commit('setHeats', newHeats)
}

export const loadResults = async ({ commit, state }, datos) => {
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${datos.meet_id}/results/${datos.event_id}.json?t=${date}`)
    const data = await response.json()
    //const { data } = await swimRankingsApi.get(`/${datos.meet_id}/results/${datos.event_id}.json`, { cache: false })
    if (!data) {
        commit('setResults', [])
        return
    }

    let resultsArr = []

    let estado = ''
    let estado_class = ''
    if(data.status == 5) {
        estado = 'RESULTADOS OFICIALES'
        estado_class = 'badge-primary'
    } else {
        estado = 'RESULTADOS PROVISIONALES'
        estado_class = 'badge-danger'
    }

    const agegroups = state.agegroups
    for(const agegroup of data.agegroups) {
        const agegroupName = getAgregroupName(agegroups, agegroup.id)
        let resultsArr2 = []
        for(const r of agegroup.results) {
            let place = r.place
            if(r.place < 0) {
                if(r.entrystatus) {
                    place = r.entrystatus
                } else {
                    place = 'DSQ'
                }
            }
            let diff_time = ''
            if(r.diff) {
                diff_time = r.diff
            }
            let medal_class = ''
            if(r.medal) {
                switch(r.medal) {
                    case 1: 
                        medal_class='gold' 
                        break
                    case 2: 
                        medal_class='silver' 
                        break
                    case 3: 
                        medal_class='bronze' 
                        break
                }
            }

            let diffEntrySwim = ''

            if((r.swimtime != '') && (r.entrytime != '')) {
                diffEntrySwim = difTimeEntryResult(r.entrytime, r.swimtime)
            }
           
            let arrSplits = []
            if(r.splits) {
                for(let clave in r.splits) {
                    const times = r.splits[clave].split(' ')
                    //console.log(times)
                    arrSplits.push({
                        meters: clave,
                        split_time: times[0],
                        partial: times[2]
                    })
                }
            }
            resultsArr2.push({
                isrelay: datos.isrelay,
                place_name: place,
                diff_time,
                medal_class,
                diff_entry: diffEntrySwim,
                splits_arr: arrSplits,
                ...r
            })
        }
        const result = {
            id: agegroup.id,
            name: agegroupName.name,
            estado,
            estado_class,
            results: resultsArr2
        }
        resultsArr.push(result)
    }

    commit('setResults', resultsArr)
}

export const loadMain = async ({ commit }, meet_id) => {
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/main.json?t=${date}`,)
    const data = await response.json()
    //const { data } = await swimRankingsApi.get(`/${meet_id}/main.json`, { cache: false })
    if (!data) {
        commit('setMain', [])
        return
    }

    commit('setMain', data)
}

export const loadClubs = async ({commit}, meet_id) => {
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/clubs.json?t=${date}`,)
    const data = await response.json()
    //const { data } = await swimRankingsApi.get(`/${meet_id}/main.json`, { cache: false })
    if (!data) {
        commit('setClubs', [])
        return
    }

    commit('setClubs', data)
}

export const loadAthletes = async ({commit}, meet_id) => {
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/athletes.json?t=${date}`,)
    const data = await response.json()
    //const { data } = await swimRankingsApi.get(`/${meet_id}/main.json`, { cache: false })
    if (!data) {
        commit('setAthletes', [])
        return
    }

    commit('setAthletes', data)
}

export const loadSessions = async ({commit}, meet_id) => {
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/eventsBySession.json?t=${date}`,)
    const data = await response.json()
    //const { data } = await swimRankingsApi.get(`/${meet_id}/main.json`, { cache: false })
    if (!data) {
        commit('setSessions', [])
        return
    }

    commit('setSessions', data.sessions)
}

export const loadAgeGroups = async ({ commit }, meet_id) => {
    const date=new Date().getTime()
    const response = await fetch(`https://live.swimrankings.net/meets/${meet_id}/agegroups.json?t=${date}`,)
    const data = await response.json()
    //const { data } = await swimRankingsApi.get(`/${meet_id}/agegroups.json`, { cache: false })
    let agegroups = []
    for (let clave in data) {
        const agegroup = {
            id: data[clave].id,
            name: data[clave].name
        }

        agegroups.push(agegroup)
    }
    commit('setAgegroups', agegroups)
}

export const loadEntriesAthlete = async ({commit, state}, data) => {
    const date=new Date().getTime()
    const eventos = []
    for(const ev of state.events) {
         for(const entry of ev.entries) {
             if(entry.athleteid == data.athleteid) {
                 const response = await fetch(`https://live.swimrankings.net/meets/${data.meet_id}/heats/${ev.id}.json?t=${date}`)
                 const dataHeat = await response.json()
                 if(dataHeat.heats.length >= 1) {
                     //Hay series sorteadas
                     for(const heat of dataHeat.heats) {
                         for(const entry of heat.entries) {
                             if(entry.athleteid == data.athleteid) {
                                 eventos.push({
                                     evento: ev,
                                     status: heat.status,
                                     serie: heat.heatinfo.code,
                                     calle: entry.lane,
                                     ...entry
                                 })
 
                             }
                         }
                     }
                 } else {
                     //No hay series sorteadas
                     eventos.push({
                         evento: ev,
                         status: 1,
                         serie: null,
                         calle: null,
                         ...entry
                     })
                 }
 
             }
         }
    }
    commit('setEventsAthlete', eventos)
   
}

export const loadEntriesAthlete2 = async ({commit, state}, data) => {
    const date=new Date().getTime()
    const eventos = []
    for(const ev of state.eventsclub) {
         for(const entry of ev.entries) {
             if(entry.athleteid == data.athleteid) {
                 const response = await fetch(`https://live.swimrankings.net/meets/${data.meet_id}/heats/${ev.id}.json?t=${date}`)
                 const dataHeat = await response.json()
                 if(dataHeat.heats.length >= 1) {
                     //Hay series sorteadas
                     for(const heat of dataHeat.heats) {
                         for(const entry of heat.entries) {
                             if(entry.athleteid == data.athleteid) {
                                 eventos.push({
                                     evento: ev,
                                     status: heat.status,
                                     serie: heat.heatinfo.code,
                                     calle: entry.lane,
                                     ...entry
                                 })
 
                             }
                         }
                     }
                 } else {
                     //No hay series sorteadas
                     eventos.push({
                         evento: ev,
                         status: 1,
                         serie: null,
                         calle: null,
                         ...entry
                     })
                 }
 
             }
         }
    }
    commit('setEventsAthlete', eventos)
   
}
