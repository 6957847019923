import axios from 'axios'

const appApi = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
})


appApi.interceptors.request.use((config) => {
    config.headers = {
        'x-access-token': (localStorage.getItem('admToken'))
    }
    return config
})

export default appApi