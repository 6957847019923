/*
export const myGetter = (state) => {
    return state.xxx
}
*/
export const getCompetitions = (state) => (finalizados) => {
    if(finalizados) {
        return state.competitions
    } else {
        const lstCompetitions = []
        const hoy = new Date()
        for(const competition of state.competitions) {
            const fechaFinEvento = new Date(competition.enddate)
            const diferencia = (hoy.getTime() - fechaFinEvento.getTime()) / 1000 / 60 / 60 / 24
            if(diferencia < 2) {
                lstCompetitions.push(competition)
            }
        }
        return lstCompetitions
    }
}

export const getEntries = (state) => {
    return state.entries
}

export const getEvents = (state) => (term) => {
    if(term.length == 0) {
        return state.events
    } else {
        return state.events.filter(ev => ev.search.toLowerCase().includes(term.toLocaleLowerCase()))
    }
}

export const getHeats = (state) => {
    return state.heats
}

export const getAgeGroups = (state) => {
    return state.agegroups
}

export const getResults = (state) => {
    return state.results
}

export const getLoading = (state) => {
    return state.isLoading
}

export const getEventId = (state) => {
    return state.event_id
}

export const getIsRelay = (state) => {
    return state.is_relay
}

export const getMain = (state) => {
    return state.main
}

export const getClubes = (state) => (code) => {
    const club = state.clubs.filter(c => c.code == code)
    if(club.length >= 1) {
        const nadadores = state.athletes.filter(athlete => athlete.clubid == club[0].id)
        return nadadores.length
    }
}

export const getNadadoresClub = (state) => (code, term) => {
    const club = state.clubs.filter(c => c.code == code)
    if(club.length >= 1) {
        const nadadores = state.athletes.filter(athlete => athlete.clubid == club[0].id)
        if(term.length === 0) return nadadores

        return nadadores.filter(ev => ev.fullname.toLowerCase().includes(term.toLocaleLowerCase()))
    }
}

export const getNadadoresInscritos = (state) => (code, genderRaw) => {
    let gender = genderRaw
    switch(genderRaw) {
        case '11': 
            gender = '1' 
            break
        case '12': gender = '2' 
            break
        default:
            gender = genderRaw
            break
    }
    const nadadores = []
    const club = state.clubs.filter(c => c.code == code)
    if(club.length >= 1) {
        for(const athlete of state.athletes) {
            if(gender == '3') {
                //Mixto
                if(athlete.clubid == club[0].id) {
                    nadadores.push({
                        label: `${athlete.fullname} (${athlete.yob})`,
                        id: athlete.id,
                        yob: athlete.yob,
                        lastname: athlete.lastname,
                        firstname: athlete.firstname,
                        fullname: athlete.fullname,
                        gender: athlete.gender
                    })
                }
            } else {
                if((athlete.clubid == club[0].id) && (athlete.gender == gender)) {
                    nadadores.push({
                        label: `${athlete.fullname} (${athlete.yob})`,
                        id: athlete.id,
                        yob: athlete.yob,
                        lastname: athlete.lastname,
                        firstname: athlete.firstname,
                        fullname: athlete.fullname,
                        gender: athlete.gender
                    })
                }
            }
        }
        return nadadores
    }
}

export const getPruebas = (state) => (code, term) => {
    //Obtengo el club para el id
    const club = state.clubs.filter(c => c.code == code)
    let arrPruebas = []
    if(club.length >= 1) {
        for(const ev of state.events) {
            for(const entry of ev.entries) {
                if((entry.clubid == club[0].id) && (entry.entrystatus != "RJC")) {
                    arrPruebas.push(ev)
                    break
                }
            }
        }

        if(term.length === 0)  {
            return arrPruebas
        } else {
            return arrPruebas.filter(ev => ev.prueba.toLowerCase().includes(term.toLowerCase()))
        }

    }
}

export const getPruebas2 = (state) => (code, term) => {
    //Obtengo el club para el id
    const club = state.clubs.filter(c => c.code == code)
    let arrPruebas = []
    if(club.length >= 1) {
        for(const ev of state.eventsclub) {
            for(const entry of ev.entries) {
                if((entry.clubid == club[0].id) && (entry.entrystatus != "RJC")) {
                    arrPruebas.push(ev)
                    break
                }
            }
        }

        if(term.length === 0)  {
            return arrPruebas
        } else {
            return arrPruebas.filter(ev => ev.prueba.toLowerCase().includes(term.toLowerCase()))
        }

    }
}

export const getPruebasNadador = (state) => {
  return state.eventsAthlete
}

export const getSessions = (state) => {
  return state.sessions
}

export const isLoadingEntries = (state) => {
  return state.isLoadingEntries
}

export const getNadadoresPrueba = (state) => (code) => {
    const club = state.clubs.filter(c => c.code == code)

    const entries = []
    if(club.length >= 1) {
        if(state.heats.length >= 1) {
            //Hay series sorteadas
            for(const heat of state.heats) {
                for(const entry of heat.entries) {
                    if(entry.clubid == club[0].id) {
                        entries.push({
                            status: heat.status,
                            serie: heat.heatinfo.code,
                            total: state.heats.length,
                            calle: entry.lane,
                            ...entry
                        })
                    }
                }
            }
        } else {
            // No hay series sorteadas
            for(const entry of state.entries) {
                if(entry.clubid == club[0].id) {
                    entries.push({
                        status: 1,
                        serie: null,
                        total: null,
                        calle: null,
                        ...entry
                    })
                }
            }
        }
    }
    return entries

}

export const getClubesEvento = (state) => (term) => {
    const lstClubes = []
    const clubes = state.clubs.filter(club => club.name.toLowerCase().includes(term.toLowerCase()))
    if(clubes.length >= 1) {
        for(const club of clubes) {
            let arrNadadores = []
            const nadadores = state.athletes.filter(athlete => athlete.clubid == club.id)
            for(const nadador of nadadores) {
                arrNadadores.push(nadador)
            }
            //let arrEntries = []
            let participaciones = 0
            for(const ev of state.eventsclub) {
                for(const entry of ev.entries) {
                    if((entry.clubid == club.id) && (entry.entrystatus != "RJC")) {
                        participaciones++
                    }
                }
            }

            lstClubes.push({
                club,
                nadadores: arrNadadores,
                participaciones
            })
        }
    }
    return lstClubes
}

export const getNadadoresTotales = (state) => (term) => {

    const athletes = state.athletes.filter(a => a.fullname.toLowerCase().includes(term.toLowerCase()))
    let arrAthletes = []
    for(const athlete of athletes) {
        const club = state.clubs.filter(c => c.id == athlete.clubid)
        let participaciones = 0
            for(const ev of state.eventsclub) {
                for(const entry of ev.entries) {
                    if((entry.athleteid == athlete.id) && (entry.entrystatus != "RJC")) {
                        participaciones++
                    }
                }
            }
        arrAthletes.push({
            ...athlete,
            club: club[0],
            participaciones
        })
    }

    return arrAthletes

}

export const getEventosSesion = (state) => (number) => {
    const lstEventos = []
    for(const sesion of state.sessions) {
        if(sesion.number == number) {
            for(const evento of sesion.events) {
                lstEventos.push(evento.id)
            }
        }
    }

    return state.events.filter(ev => lstEventos.includes(ev.id))
}

export const getPoints = (state) => {
    return state.pointscore
}