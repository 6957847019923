export const loginSecretaria = (state, data) => {
    if(data.token) {
        localStorage.setItem('admToken', data.token)
        state.idToken = data.token
    }
    state.status = 'authenticated'
    state.club_id = data.club_id
    state.usu_id = data.usu_id
    state.email = data.email
    state.club_nombre = data.club_nombre
    state.rol = data.rol
    state.sgf = data.sgf
}

export const setSolicitudes = (state, data) => {
    state.solicitudes = data
}

export const setUsuarios = (state, data) => {
    state.usuarios = data
}

export const logout = (state) =>  {
    state.idToken = null
    state.status = 'not-authenticated'
    state.club_id = 0
    state.usu_id = 0
    state.club_nombre = ''
    state.email = ''
    state.rol = ''
    state.sgf = false

    localStorage.removeItem('admToken')
}

export const setCompeticiones = (state, data) => {
    state.competiciones = data
}

export const setRelays = (state, relays) => {
    state.relays = relays
}

export const setSeries = (state, series) => {
    state.series = series
}