<template>

<router-view></router-view>
  
</template>

<style>
.azul {
  color: #0D99FF;
}
</style>
