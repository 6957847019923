export const getClubId = (state) => {
    return state.club_id
}

export const getUsuId = (state) => {
    return state.usu_id
}

export const getEmail = (state) => {
    return state.email
}

export const getClubNombre = (state) => {
    return state.club_nombre
}

export const getRol = (state) => {
    return state.rol
}

export const getSolicitudes = (state) => (term) => {
    if(term.length === 0) return state.solicitudes

    return state.solicitudes.filter(sol => sol.sol_deportista.toLowerCase().includes(term.toLocaleLowerCase()))
}

export const getCompeticiones = (state) => (finalizados, term) => {
    if(term.length === 0) {
        if(finalizados) {
            return state.competiciones
        } else {
            const lstCompetitions = []
            const hoy = new Date()
            for(const competition of state.competiciones) {
                const fechaFinEvento = new Date(competition.meet_fecha_fin)
                const diferencia = (hoy.getTime() - fechaFinEvento.getTime()) / 1000 / 60 / 60 / 24
                if(diferencia < 2) {
                    lstCompetitions.push(competition)
                }
            }
            return lstCompetitions
        }
    } else {
        if(finalizados) {
            return state.competiciones.filter(m => m.meet_nombre.toLowerCase().includes(term.toLocaleLowerCase()))
        } else {
            const lstCompetitions = []
            const hoy = new Date()
            for(const competition of state.competiciones) {
                const fechaFinEvento = new Date(competition.meet_fecha_fin)
                const diferencia = (hoy.getTime() - fechaFinEvento.getTime()) / 1000 / 60 / 60 / 24
                if(diferencia < 2) {
                    lstCompetitions.push(competition)
                }
            }
            return lstCompetitions.filter(m => m.meet_nombre.toLowerCase().includes(term.toLocaleLowerCase()))
        }
    }
}

export const getSeries = (state) => {
    return state.series
}

export const getSgf = (state) => {
    return state.sgf
}

export const getRelays = (state) => {
    return state.relays.sort((a,b) => {
        if(a.number > b.number) return 1 
        if(a.number < b.number) return -1
        return 0 
    })
}

export const getUsuarios = (state) => (filtro) => {
    if(filtro.term.length === 0) {
        return state.usuarios
    } else {
        if(filtro.field == "CLUB") {
            return state.usuarios.filter(usuario => usuario.club_nombre.toLowerCase().includes(filtro.term.toLocaleLowerCase()))
        } else {
            return state.usuarios.filter(usuario => usuario.usu_email.toLowerCase().includes(filtro.term.toLocaleLowerCase()))
        }
    }
}

